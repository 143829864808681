<template>
  <div v-if="isLoading || episodes == null" class="flex justify-center ml-[-10px] mt-20">
    <ProgressSpinner style="width:50px;height:50px" strokeWidth="8"/>
  </div>
  <div v-else class="detail-sidebar">
    <div class="relative flex items-center justify-between ep-list-tool">
      <div class="navbar-start ">
        <h3 v-if="disableSearch" class="text-2xl mb-5">Aнгиуд - {{ episodes.length }}</h3>
        <Button @click="checkShape()"
                v-if="!disableSearch"
                v-tooltip.top="{ value: shape ? 'Хүснэгтээр' : 'Жагсаалтаар'}"
                :icon="shape ? 'pi pi-th-large' : 'pi pi-list text-2x'"
                class="p-button-outlined p-button-secondary"></Button>
      </div>

      <div class="navbar-end">
        <div v-if="!disableSearch" class="p-inputgroup">
          <span class="p-float-label p-input-icon-left w-[80px] ml-2">
              <i class="pi pi-search ml-1 search-filter-icon"/>
              <InputText id="inputtext-left" type="text" v-model="q" @input="searchEpisode(q)"/>
          </span>

          <Button v-if="disableSearch" @click="checkShape()"
                  v-tooltip.top="{ value: shape ? 'Хүснэгтээр' : 'Жагсаалтаар'}"
                  :icon="shape ? 'pi pi-th-large' : 'pi pi-list text-2x'"
                  class="p-button-outlined p-button-secondary"></Button>

          <span class="p-inputgroup-addon w-24 cursor-pointer" @click="checkSort()"
                v-tooltip.top="{ value: sort ? 'Өсөх' : 'Буурах'}">
            {{ sort ? 'А-Я' : 'Я-А' }} <i class="pl-2" :class="sort ? 'pi pi-arrow-down' : 'pi pi-arrow-up'"></i>
          </span>
        </div>

        <div v-else class="right-tools">
<!--          <Button @click="checkShape()"-->
<!--                  v-tooltip.top="{ value: shape ? 'Хүснэгтээр' : 'Жагсаалтаар'}"-->
<!--                  :icon="shape ? 'pi pi-th-large' : 'pi pi-list text-2x'"-->
<!--                  class="p-button-outlined p-button-secondary"></Button>-->

          <Button v-if="disableSearch" class="p-button-outlined p-button-secondary" @click="checkSort()"
                  v-tooltip.top="{ value: sort ? 'Өсөх' : 'Буурах'}">{{ sort ? 'A-Я' : 'Я-A' }}
          </Button>

          <Dropdown v-if="seasons" :options="seasons"
                    v-model="currentSeason"
                    :optionValue="getVal" optionLabel="title" placeholder="Бүлэг"
                    @change="onSeasonChange"
                    filterPlaceholder="Бүлэг хайх"/>
        </div>
      </div>
    </div>

    <div v-if="isLoading"></div>
    <ScrollPanel v-else class="ep-custombar">
      <div v-show="shape" class="flex-1 overflow-y-auto h-[100%]">
        <a href="javascript:void(0)" v-for="item in episodes" :key="item.id" @click="gotoWatch(item.id, item.anime_id)"
           :class="`flex flex-col bg-[#fff] shadow-md md:flex-row border border-transparent rounded mb-3 ep-link-hz ep-list-item ${item.id == $route.params.episode ? 'active' : ''}`">
          <Image :src="item.image" class="object-cover md:w-40 rounded-l" alt=""/>
          <div class="flex flex-col justify-between p-3 leading-normal ep-list-item-info">
            <div class="ep-list-item-info-header">
              <h3 v-if="item.title != null" class="mb-2 text-sm tracking-tight text-gray-300">{{ item.title }}</h3>
              <h5>{{ item.number }}-р анги</h5>
            </div>
            <p class="desc" v-html="item.description"></p>
          </div>
        </a>
      </div>

      <!--grid-->
      <div v-show="!shape"
           class="grid grid-cols-6 sm:grid-cols-9 md:grid-cols-10 lg:grid-cols-6 justify-center gap-2 my-1">
        <a href="javascript:void(0)" @click="gotoWatch(item.id)" v-for="item in episodes" :key="item.id"
           :class="`rounded p-3 shadow-md text-center bg-[#fff] border border-transparent ep-link ${item.id == $route.params.episode ? 'active' : ''}`">
          {{ item.number }}
        </a>
      </div>
    </ScrollPanel>
    <!--    </div>-->
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Image from "./Image.vue";
import Dropdown from "primevue/dropdown";

export default {
  name: "episode-list",
  props: ["mediaId", 'disableSearch', 'isList', 'seasons'],
  components: {
    Image,
    Dropdown,
  },
  data() {
    return {
      isLoading: false,
      isLoadingGroupEpisodes: false,
      sortDirection: 'asc',
      groups: [],
      q: "",
      shape: true,
      sort: true,
      value1: 'Off',
      value2: null,
      value3: null,
      options: ['Off', 'On'],
      paymentOptions: [
        {name: 'Option 1', value: 1},
        {name: 'Option 2', value: 2},
        {name: 'Option 3', value: 3}
      ],
      justifyOptions: [
        {icon: 'pi pi-align-left', value: 'left'},
        {icon: 'pi pi-align-right', value: 'Right'},
        {icon: 'pi pi-align-center', value: 'Center'}
      ],
      currentSeason: null
    }
  },

  computed: {
    ...mapGetters(['episodes', 'allEpisodes', 'episodeGroupList', 'sortDirection']),
  },

  mounted() {
    // if (this.media.isGroup) {
    //     if (this.groups.length === 0) {
    //         this.getGroups(this.episode.anime_id);
    //     }
    // } else {
    //     if (this.episodes.length === 0) {
    //         this.getEpisodes(this.episode.anime_id);
    //     }
    // }

    console.log(this.mediaId);

    this.getEpisodes({vm: this, mediaId: this.mediaId})
    if (this.isList) {
      this.shape = true;
    }
  },

  created() {
    console.log(this.seasons, this.mediaId);

    if (this.seasons) {
      this.currentSeason = this.mediaId.toString();
    }
  },

  methods: {
    ...mapActions(['getGroups', 'getEpisodes']),

    // getEpisodes(anime) {
    //     this.isLoadingEpisodes = true;
    //     axios
    //         .get(`/api/m/episodes/${anime}/${this.$user ? this.$user.id : null}`)
    //         .then(({data}) => {
    //             this.episodes = data;
    //             this.isLoadingEpisodes = false;
    //         });
    // },
    //
    // getGroups(anime) {
    //     this.isLoadingEpisodes = true;
    //     axios
    //         .get(`/api/m/groups/${anime}/${this.$user ? this.$user.id : null}`)
    //         .then(({data}) => {
    //             this.groups = data;
    //             this.isLoadingEpisodes = false;
    //         });
    // },
    //
    // getByGroup(id) {
    //     let group = this.groups.find((item) => item.id === id);
    //     if (group.episodes == undefined || group.episodes == "") {
    //         this.getEpisodesByGroup(id);
    //     }
    // },
    //
    // getEpisodesByGroup(group) {
    //     this.isLoadingGroupEpisodes = true;
    //     axios
    //         .get(`/api/m/episodes/group/${group}/${this.$user ? this.$user.id : null}`)
    //         .then(({data}) => {
    //             this.groups = this.groups.map((item) => {
    //                 if (item.id === group) {
    //                     item.episodes = data;
    //                 }
    //                 return item;
    //             });
    //             this.isLoadingGroupEpisodes = false;
    //         });
    // },
    //
    searchEpisode(q) {
      console.log(q);
      this.$store.dispatch('searchEpisode', q);
    },

    gotoWatch(id) {
      this.$router.push(`/watch/${this.mediaId}/${id}`);
      this.$store.dispatch('switchEpisode');
      this.$store.dispatch('getEpisodeDetail', {vm: this, episodeID: id});
    },

    checkShape() {
      this.shape = !this.shape;
    },

    checkSort() {
      this.sort = !this.sort;
      this.$store.dispatch('sortEpisode');
    },

    onSeasonChange(val) {
      this.getEpisodes({vm: this, mediaId: val.value})
    },

    getVal(item) {
      return item.id.toString();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem;
    line-height: 1.2;
    margin: 0;
  }

  &.ep-custombar {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .p-scrollpanel-bar {
      width: 4px;
    }
  }
}
</style>
